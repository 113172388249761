var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "w-100 border-info rounded-lg mb-0 shadow-take-photo-itineraries",
    attrs: {
      "no-body": "",
      "header-class": "bg-light-warning ".concat(_vm.isMobileView ? 'px-25' : 'px-1', " py-25")
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        var _vm$itinerary, _vm$itinerary2, _vm$itinerary3, _vm$itinerary4, _vm$itinerary5, _vm$itinerary5$, _vm$itinerary6, _vm$itinerary7, _vm$itinerary8, _vm$itinerary8$, _vm$itinerary9, _vm$itinerary9$, _vm$itinerary10, _vm$itinerary10$;
        return [_c('div', {
          class: "w-100 d-flex flex-wrap align-items-center justify-content-around ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-4')
        }, [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('IAmLogoAirline', {
          attrs: {
            "blob": "",
            "longLogo": "",
            "airline": ((_vm$itinerary = _vm.itinerary) === null || _vm$itinerary === void 0 ? void 0 : _vm$itinerary[0].operating) === 'BL' ? (_vm$itinerary2 = _vm.itinerary) === null || _vm$itinerary2 === void 0 ? void 0 : _vm$itinerary2[0].operating : ((_vm$itinerary3 = _vm.itinerary) === null || _vm$itinerary3 === void 0 ? void 0 : _vm$itinerary3[0].airline.split(' | ')[0]) || ((_vm$itinerary4 = _vm.itinerary) === null || _vm$itinerary4 === void 0 ? void 0 : _vm$itinerary4[0].operating),
            "height": _vm.isMobileView ? '30' : '40'
          }
        })], 1), _c('div', {
          staticClass: "fw-700 text-body flex-1 d-flex-center text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.resolveAirport((_vm$itinerary5 = _vm.itinerary) === null || _vm$itinerary5 === void 0 ? void 0 : (_vm$itinerary5$ = _vm$itinerary5[0]) === null || _vm$itinerary5$ === void 0 ? void 0 : _vm$itinerary5$.departure.iataCode)) + " - " + _vm._s(_vm.resolveAirport((_vm$itinerary6 = _vm.itinerary) === null || _vm$itinerary6 === void 0 ? void 0 : (_vm$itinerary7 = _vm$itinerary6[_vm.itinerary.length - 1]) === null || _vm$itinerary7 === void 0 ? void 0 : _vm$itinerary7.arrival.iataCode)) + " ")]), _c('div', {
          staticClass: "fw-700 text-body"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.convertISODateTime((_vm$itinerary8 = _vm.itinerary) === null || _vm$itinerary8 === void 0 ? void 0 : (_vm$itinerary8$ = _vm$itinerary8[0]) === null || _vm$itinerary8$ === void 0 ? void 0 : _vm$itinerary8$.departure.at, ((_vm$itinerary9 = _vm.itinerary) === null || _vm$itinerary9 === void 0 ? void 0 : (_vm$itinerary9$ = _vm$itinerary9[0]) === null || _vm$itinerary9$ === void 0 ? void 0 : _vm$itinerary9$.departure.timezone) || ((_vm$itinerary10 = _vm.itinerary) === null || _vm$itinerary10 === void 0 ? void 0 : (_vm$itinerary10$ = _vm$itinerary10[0]) === null || _vm$itinerary10$ === void 0 ? void 0 : _vm$itinerary10$.departure.timeZone)).date) + " ")])])])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.itinerary, function (segment, indexSegment) {
    var _segment$departure, _segment$departure2, _vm$convertISODateTim, _segment$departure3, _segment$departure4, _segment$departure5, _vm$convertISODateTim2, _segment$departure6, _segment$departure7, _segment$departure8, _segment$arrival, _segment$arrival2, _vm$convertISODateTim3, _segment$arrival3, _segment$arrival4, _segment$arrival5, _vm$convertISODateTim4, _segment$arrival6, _segment$arrival7, _segment$arrival8;
    return _c('Stack', {
      key: segment.id
    }, [_c('BRow', {
      staticClass: "px-50 py-1"
    }, [_c('BCol', {
      attrs: {
        "cols": "7",
        "md": "8"
      }
    }, [_c('BRow', [_c('BCol', {
      staticClass: "pr-25",
      attrs: {
        "cols": "6"
      }
    }, [_c('Stack', [_c(_vm.isMobileView ? 'Stack' : 'HStack', {
      tag: "components",
      staticClass: "flex-wrap text-nowrap"
    }, [_vm._v(" " + _vm._s(_vm.resolveAirport(segment === null || segment === void 0 ? void 0 : (_segment$departure = segment.departure) === null || _segment$departure === void 0 ? void 0 : _segment$departure.iataCode)) + ": "), _c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" (" + _vm._s(segment === null || segment === void 0 ? void 0 : (_segment$departure2 = segment.departure) === null || _segment$departure2 === void 0 ? void 0 : _segment$departure2.iataCode) + ") ")])]), _c('HStack', {
      staticClass: "flex-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.departTime')) + ": "), _c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s((_vm$convertISODateTim = _vm.convertISODateTime(segment === null || segment === void 0 ? void 0 : (_segment$departure3 = segment.departure) === null || _segment$departure3 === void 0 ? void 0 : _segment$departure3.at, (segment === null || segment === void 0 ? void 0 : (_segment$departure4 = segment.departure) === null || _segment$departure4 === void 0 ? void 0 : _segment$departure4.timezone) || (segment === null || segment === void 0 ? void 0 : (_segment$departure5 = segment.departure) === null || _segment$departure5 === void 0 ? void 0 : _segment$departure5.timeZone))) === null || _vm$convertISODateTim === void 0 ? void 0 : _vm$convertISODateTim.time) + " ")])]), _c('div', {
      staticClass: "fw-800 text-nowrap"
    }, [_vm._v(" " + _vm._s((_vm$convertISODateTim2 = _vm.convertISODateTime(segment === null || segment === void 0 ? void 0 : (_segment$departure6 = segment.departure) === null || _segment$departure6 === void 0 ? void 0 : _segment$departure6.at, (segment === null || segment === void 0 ? void 0 : (_segment$departure7 = segment.departure) === null || _segment$departure7 === void 0 ? void 0 : _segment$departure7.timezone) || (segment === null || segment === void 0 ? void 0 : (_segment$departure8 = segment.departure) === null || _segment$departure8 === void 0 ? void 0 : _segment$departure8.timeZone))) === null || _vm$convertISODateTim2 === void 0 ? void 0 : _vm$convertISODateTim2.date) + " ")])], 1)], 1), _c('BCol', {
      staticClass: "pr-25",
      attrs: {
        "cols": "6"
      }
    }, [_c('Stack', [_c(_vm.isMobileView ? 'Stack' : 'HStack', {
      tag: "components",
      staticClass: "flex-wrap text-nowrap"
    }, [_vm._v(" " + _vm._s(_vm.resolveAirport(segment === null || segment === void 0 ? void 0 : (_segment$arrival = segment.arrival) === null || _segment$arrival === void 0 ? void 0 : _segment$arrival.iataCode)) + ": "), _c('div', [_vm._v(" (" + _vm._s(segment === null || segment === void 0 ? void 0 : (_segment$arrival2 = segment.arrival) === null || _segment$arrival2 === void 0 ? void 0 : _segment$arrival2.iataCode) + ") ")])]), _c('HStack', {
      staticClass: "flex-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.arrivalTime')) + ": "), _c('div', [_vm._v(" " + _vm._s((_vm$convertISODateTim3 = _vm.convertISODateTime(segment === null || segment === void 0 ? void 0 : (_segment$arrival3 = segment.arrival) === null || _segment$arrival3 === void 0 ? void 0 : _segment$arrival3.at, (segment === null || segment === void 0 ? void 0 : (_segment$arrival4 = segment.arrival) === null || _segment$arrival4 === void 0 ? void 0 : _segment$arrival4.timezone) || (segment === null || segment === void 0 ? void 0 : (_segment$arrival5 = segment.arrival) === null || _segment$arrival5 === void 0 ? void 0 : _segment$arrival5.timeZone))) === null || _vm$convertISODateTim3 === void 0 ? void 0 : _vm$convertISODateTim3.time) + " ")])]), _c('div', {
      staticClass: "text-nowrap"
    }, [_vm._v(" " + _vm._s((_vm$convertISODateTim4 = _vm.convertISODateTime(segment === null || segment === void 0 ? void 0 : (_segment$arrival6 = segment.arrival) === null || _segment$arrival6 === void 0 ? void 0 : _segment$arrival6.at, (segment === null || segment === void 0 ? void 0 : (_segment$arrival7 = segment.arrival) === null || _segment$arrival7 === void 0 ? void 0 : _segment$arrival7.timezone) || (segment === null || segment === void 0 ? void 0 : (_segment$arrival8 = segment.arrival) === null || _segment$arrival8 === void 0 ? void 0 : _segment$arrival8.timeZone))) === null || _vm$convertISODateTim4 === void 0 ? void 0 : _vm$convertISODateTim4.date) + " ")])], 1)], 1)], 1)], 1), _c('BCol', {
      staticClass: "pl-25",
      attrs: {
        "cols": "5",
        "md": "4"
      }
    }, [_c('Stack', [_c('HStack', {
      staticClass: "flex-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.airlineShort')) + ": "), _c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode((segment === null || segment === void 0 ? void 0 : segment.operating) === 'BL' ? segment === null || segment === void 0 ? void 0 : segment.operating : (segment === null || segment === void 0 ? void 0 : segment.airline) || (segment === null || segment === void 0 ? void 0 : segment.operator))) + " ")])]), segment.airline && segment.flightNumber ? _c('HStack', {
      staticClass: "flex-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.flight')) + ": "), _c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(segment.airline) + _vm._s(segment.flightNumber) + " ")])]) : _vm._e(), segment.duration ? _c('HStack', {
      staticClass: "flex-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightDuration')) + ": "), _c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(_vm.toHoursAndMinutes(segment.duration)) + " ")])]) : _vm._e(), segment.airCraft ? _c('HStack', {
      staticClass: "flex-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.airCraft')) + ": "), _c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(_vm.convertShortenAircraft(segment.airCraft)) + " ")])]) : _vm._e()], 1)], 1)], 1), indexSegment !== _vm.itinerary.length - 1 ? _c('Stack', {
      staticClass: "px-50 gap-2"
    }, [segment.isNextAirport ? _c('div', [_c('b-alert', {
      staticClass: "py-25 px-1 shadow-sm d-flex-center mb-0",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextAirport')))])], 1)], 1) : _vm._e(), segment.isNextDay ? _c('div', [_c('b-alert', {
      staticClass: "py-25 px-1 shadow-sm d-flex-center mb-0",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextDay')))])], 1)], 1) : _vm._e(), segment.transitTime ? _c('div', [_c('b-alert', {
      staticClass: "py-25 px-1 shadow-sm d-flex-center mb-0",
      attrs: {
        "show": "",
        "variant": "secondary"
      }
    }, [_c('HStack', {
      staticClass: "gap-3",
      attrs: {
        "center": ""
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning",
      attrs: {
        "icon": "InfoIcon",
        "size": "16"
      }
    }), _c('HStack', {
      staticClass: "text-dark"
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.transitIn')) + " "), _c('span', {
      staticClass: "fw-700 text-info"
    }, [_vm._v(" " + _vm._s(_vm.resolveAirport(segment.arrival.iataCode)) + " ")])]), _c('span', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.transitTime')) + ": "), _c('span', {
      staticClass: "fw-700 text-info"
    }, [_vm._v(_vm._s(segment.transitTime))])])], 1)], 1)], 1) : _vm._e()]) : _vm._e()], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }