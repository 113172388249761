<template lang="">
  <BCard
    class="w-100 border-info rounded-lg mb-0 shadow-take-photo-itineraries"
    no-body
    :header-class="`bg-light-warning ${isMobileView ? 'px-25': 'px-1'} py-25`"
  >
    <template #header>
      <div :class="`w-100 d-flex flex-wrap align-items-center justify-content-around ${isMobileView ? 'font-small-4' : 'font-medium-4'}`">
        <!-- ANCHOR - Logo -->
        <div class="d-flex-center">
          <IAmLogoAirline
            blob
            longLogo
            :airline="itinerary?.[0].operating === 'BL' ? itinerary?.[0].operating : (itinerary?.[0].airline.split(' | ')[0] || itinerary?.[0].operating)"
            :height="isMobileView ? '30' : '40'"
          />
        </div>
        <div class="fw-700 text-body flex-1 d-flex-center text-nowrap">
          {{ resolveAirport(itinerary?.[0]?.departure.iataCode) }}
          -
          {{ resolveAirport(itinerary?.[itinerary.length - 1]?.arrival.iataCode) }}
        </div>
        <div class="fw-700 text-body">
          <span>
            {{ convertISODateTime(itinerary?.[0]?.departure.at, itinerary?.[0]?.departure.timezone || itinerary?.[0]?.departure.timeZone ).date }}
          </span>
        </div>
      </div>
    </template>
    <Stack
      v-for="(segment, indexSegment) in itinerary"
      :key="segment.id"
    >
      <BRow class="px-50 py-1">
        <BCol
          cols="7"
          md="8"
        >
          <BRow>
            <BCol
              cols="6"
              class="pr-25"
            >
              <Stack>
                <components
                  :is="isMobileView ? 'Stack' : 'HStack'"
                  class="flex-wrap text-nowrap"
                >
                  {{ resolveAirport(segment?.departure?.iataCode) }}:
                  <div class="fw-800">
                    ({{ segment?.departure?.iataCode }})
                  </div>
                </components>
                <HStack class="flex-wrap">
                  {{ $t('ticket.flightsInfo.departTime') }}:
                  <div class="fw-800">
                    {{ convertISODateTime(segment?.departure?.at, segment?.departure?.timezone || segment?.departure?.timeZone )?.time }}
                  </div>
                </HStack>
                <div class="fw-800 text-nowrap">
                  {{ convertISODateTime(segment?.departure?.at, segment?.departure?.timezone || segment?.departure?.timeZone )?.date }}
                </div>
              </Stack>
            </BCol>
            <BCol
              cols="6"
              class="pr-25"
            >
              <Stack>
                <components
                  :is="isMobileView ? 'Stack' : 'HStack'"
                  class="flex-wrap text-nowrap"
                >
                  {{ resolveAirport(segment?.arrival?.iataCode) }}:
                  <div>
                    ({{ segment?.arrival?.iataCode }})
                  </div>
                </components>
                <HStack class="flex-wrap">
                  {{ $t('ticket.flightsInfo.arrivalTime') }}:
                  <div>
                    {{ convertISODateTime(segment?.arrival?.at, segment?.arrival?.timezone || segment?.arrival?.timeZone )?.time }}
                  </div>
                </HStack>
                <div class="text-nowrap">
                  {{ convertISODateTime(segment?.arrival?.at, segment?.arrival?.timezone || segment?.arrival?.timeZone )?.date }}
                </div>
              </Stack>
            </BCol>
          </BRow>
        </BCol>
        <BCol
          cols="5"
          md="4"
          class="pl-25"
        >
          <Stack>
            <HStack class="flex-wrap">
              {{ $t('flight.airlineShort') }}:
              <div class="fw-800">
                {{ getAirlineNameByCode(segment?.operating === 'BL' ? segment?.operating : (segment?.airline || segment?.operator)) }}
              </div>
            </HStack>

            <HStack
              v-if="segment.airline && segment.flightNumber"
              class="flex-wrap"
            >
              {{ $t('flight.flight') }}:
              <div class="fw-800">
                {{ segment.airline }}{{ segment.flightNumber }}
              </div>
            </HStack>

            <HStack
              v-if="segment.duration"
              class="flex-wrap"
            >
              {{ $t('flight.flightDuration') }}:
              <div class="fw-800">
                {{ toHoursAndMinutes(segment.duration) }}
              </div>
            </HStack>

            <HStack
              v-if="segment.airCraft"
              class="flex-wrap"
            >
              {{ $t('flight.airCraft') }}:
              <div class="fw-800">
                {{ convertShortenAircraft(segment.airCraft) }}
              </div>
            </HStack>
          </Stack>
        </BCol>
      </BRow>

      <Stack
        v-if="indexSegment !== itinerary.length - 1"
        class="px-50 gap-2"
      >
        <div v-if="segment.isNextAirport">
          <b-alert
            show
            variant="warning"
            class="py-25 px-1 shadow-sm d-flex-center mb-0"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="21"
              class="text-warning mr-1"
            />
            <b>{{ $t('flight.alertNextAirport') }}</b>
          </b-alert>
        </div>
        <div v-if="segment.isNextDay">
          <b-alert
            show
            variant="warning"
            class="py-25 px-1 shadow-sm d-flex-center mb-0"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="21"
              class="text-warning mr-1"
            />
            <b>{{ $t('flight.alertNextDay') }}</b>
          </b-alert>
        </div>
        <div v-if="segment.transitTime">
          <b-alert
            show
            variant="secondary"
            class="py-25 px-1 shadow-sm d-flex-center mb-0"
          >
            <HStack
              class="gap-3"
              center
            >
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="text-warning"
              />
              <HStack class="text-dark">
                {{ $t('ticket.flightsInfo.transitIn') }}
                <span class="fw-700 text-info">
                  {{ resolveAirport(segment.arrival.iataCode) }}
                </span>
              </HStack>
              <span class="text-dark">{{ $t('ticket.flightsInfo.transitTime') }}:
                <span class="fw-700 text-info">{{ segment.transitTime }}</span>
              </span>
            </HStack>
          </b-alert>
        </div>
      </Stack>
    </Stack>
  </BCard>
</template>
<script>
import {
  BAlert, BCard, BCol, BRow,
} from 'bootstrap-vue'

import { convertISODateTime, resolveAirlineFlightNumber, toHoursAndMinutes } from '@/@core/utils/filter'
import store from '@/store'
import useReservationHandle from '@/views/apps/reservation/useReservationHandle'

import { convertShortenAircraft } from '@flightv2/useBookingHandle'

export default {
  components: {
    BCard,
    BAlert,
    BCol,
    BRow,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    itinerary: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      getAirportByAirportCode,
    } = useReservationHandle()

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    const resolveAirport = code => {
      const depObj = getAirportByAirportCode(code)
      return (depObj?.cityName ?? depObj?.name ?? code).split(', ')[0]
    }
    return {
      convertISODateTime,
      resolveAirport,
      resolveAirlineFlightNumber,
      toHoursAndMinutes,
      convertShortenAircraft,
      getAirlineNameByCode,
    }
  },
}
</script>
<style lang="">

</style>
